import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { TextField } from '@ee-monorepo/shared/ui/text-field';
import { FormattedTextField } from './lazy';
import { Button } from '@ee-monorepo/shared/ui/button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { getgclCookie } from '@ee-monorepo/shared/utilities/functions';
import {
  EMAIL,
  MerchantShortFormInterface,
  PHONE_LENGTH,
  REQUIRED,
} from '@ee-monorepo/shared/utilities/types';
import { useTranslations } from 'next-intl';
import { Dispatch, SetStateAction } from 'react';
import { useMerchantLeadAPI } from '@ee-monorepo/shared/data-access/services';

export const merchantShortFormSchema = yup.object().shape({
  firstName: yup.string().required(REQUIRED).clearParams(),
  lastName: yup.string().required(REQUIRED).clearParams(),
  businessName: yup.string().required(REQUIRED).clearParams(),
  email: yup.string().required(REQUIRED).email(EMAIL),
  phoneNumber: yup
    .string()
    .required(REQUIRED)
    .digitsOnly()
    .length(10, PHONE_LENGTH),
});

interface MerchantShortFormProps {
  onSubmitSuccess: Dispatch<SetStateAction<boolean>>;
  onSubmitError: Dispatch<SetStateAction<boolean>>;
}

/* eslint-disable-next-line */
export function MerchantShortForm({
  onSubmitSuccess,
  onSubmitError,
}: MerchantShortFormProps) {
  const onSuccess = () => {
    setIsSubmitted(false);
    onSubmitSuccess(true);
    onSubmitError(false);
  };

  const onFailure = () => {
    setIsSubmitted(false);
    onSubmitError(true);
  };

  const gclID = getgclCookie();
  const t = useTranslations('forms_merchant_short_form');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { createMerchantLead, createMerchantLeadLoading } = useMerchantLeadAPI(
    t,
    onSuccess,
    onFailure
  );

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      businessName: '',
      email: '',
      phoneNumber: '',
    },
    mode: 'all',
    resolver: yupResolver(merchantShortFormSchema),
  });

  const onSubmit = async (data: MerchantShortFormInterface) => {
    setIsSubmitted(true);
    createMerchantLead({ formName: 'SHORT_FORM', data });
  };

  return (
    <form
      // className={cn(s['merchantShortFormContainer'])}
      data-testid="merchantShortForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      {gclID && gclID !== '' ? (
        <input type="hidden" id="gcl_aw_cookie" value={gclID} />
      ) : null}
      <div className="row m-0">
        <div className="md:w-1/2 md:pr-8 px-0">
          <FieldControl
            fieldName="firstName"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('firstName')}
                error={invalid}
                data-testid="firstNameTextField"
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </div>
        <div className="md:w-1/2 md:pr-0 md:mr-0 px-0">
          <FieldControl
            fieldName="lastName"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('lastName')}
                error={invalid}
                data-testid="lastNameTextField"
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </div>
      </div>
      <div className="row m-0 lg:p-0">
        <div className="w-full px-0">
          <FieldControl
            fieldName="businessName"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('businessName')}
                error={invalid}
                data-testid="businessNameTextField"
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="md:w-1/2 md:pr-8 px-0">
          <FieldControl
            fieldName="email"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('email')}
                error={invalid}
                data-testid="emailTextField"
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </div>
        <div className="md:w-1/2 md:pr-0 px-0">
          <FieldControl
            fieldName="phoneNumber"
            control={control}
            render={(field, invalid) => (
              <FormattedTextField
                {...field}
                fullWidth
                label={t('phoneNumber')}
                error={invalid}
                mask="000-000-0000"
                isPassword={false}
                maskedValue={false}
                data-testid="phoneNumberTextField"
                inputProps={{ maxLength: 10 }}
              />
            )}
          />
        </div>
        <div className="h-20"></div>
      </div>
      <div className="row m-0 absolute bottom-0 w-full left-0 bg-white p-4 md:p-6">
        <div className="flex flex-col md:flex-row px-0">
          <Button
            data-testid="submitButton"
            type="submit"
            variant={'primary'}
            disabled={isSubmitted || !isValid || createMerchantLeadLoading}
            className={'w-full p-3'}
          >
            {t('submitButton')}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default MerchantShortForm;
