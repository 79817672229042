import { SnapDialog } from '@ee-monorepo/shared/ui/dialog';
import { useMerchantShortFormContext } from '@ee-monorepo/shared/context/merchant-form';
import { MerchantShortForm } from '@ee-monorepo/forms/merchant-short-form';
import { useTranslations } from 'next-intl';
import { SharedUiMerchantSuccessBox } from '@ee-monorepo/shared/ui/merchant-success-box';
import { useState } from 'react';
import { InlineStatusMessage } from '@ee-monorepo/shared/ui/inline-status-message';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import cn from 'classnames';

export function MerchantShortFormDialog() {
  const isMobile = useHandHeld();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { tracking } = useTrackingContext();

  const { show, setShow } = useMerchantShortFormContext();
  const t = useTranslations('dialogs_merchant_short_form_dialog');

  const handleDialogClose = () => {
    setShow(false);
    setSubmitError(false);
    setSubmitSuccess(false);
    tracking?.logClickEvent({
      click_value: 'close',
      click_type: 'button',
      placement: 'request details modal',
    });
  };
  return (
    <SnapDialog
      titleAlignment={'start'}
      dialogPaperClasses={!submitSuccess ? cn('pb-24') : ''}
      hideCloseButton={submitSuccess ? true : false}
      title={!submitSuccess ? t('dialogTitle') : ''}
      id="merchant-short-form-dialog"
      data-testid="merchantShortFormDialog"
      open={show}
      fullScreenOnMobile={!submitSuccess}
      centered={true}
      dialogContentClasses={!submitSuccess ? 'h-[620px]' : ''}
      onClose={handleDialogClose}
      PaperProps={
        submitSuccess
          ? {
              style: {
                background: 'linear-gradient(0deg, #FFF 70%, #1B844A 0%)',
              },
            }
          : {
              sx: {
                maxWidth: !isMobile && '991px',
                width: !isMobile && '65%',
              },
            }
      }
    >
      {!submitSuccess && (
        <div className="flex flex-col flex-row ">
          {submitError && (
            <div className="pt-4">
              <InlineStatusMessage
                alertType="error"
                text={t('formSubmissionFailed')}
                setVisible={setSubmitError}
                visible={submitError}
                alertTitle={t('formSubmissionFailedTitle')}
              />
            </div>
          )}
          <h2
            className="headingM md:mt-0 md:mb-6 sm:mt-5 sm:mb-7 text-SnapGrey500"
            data-testid="dialogHeaderText"
          >
            {t('headerText')}
          </h2>
          <div>
            <MerchantShortForm
              onSubmitSuccess={setSubmitSuccess}
              onSubmitError={setSubmitError}
            />
          </div>
        </div>
      )}
      {submitSuccess && <SharedUiMerchantSuccessBox />}
    </SnapDialog>
  );
}

export default MerchantShortFormDialog;
